const doFetch = (path, data, {method} = {}) => {
  const csrf = document.querySelector(`meta[name='csrf-token']`).getAttribute('content');
  return fetch(path, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf
    },
    body: JSON.stringify(data),
  })
  .then(async response => {
    if (!response.ok) {
      const message = await response.text();
      throw Error(message);
    }
    return response;
  });
};

export const post = (path, data) => {
  return doFetch(path, data, {method: 'POST'});
};

export const patch = (path, data) => {
  return doFetch(path, data, {method: 'PATCH'});
};

export const postJSON = (path, data) => {
  return post(path, data)
  .then(response => {
    return response.json();
  })
};
