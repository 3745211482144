import React from 'react';
import styled from 'styled-components';

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItemLabel = styled.span`
  margin-left: 5px;
  cursor: pointer;
`;

const MenuItem = ({icon: Icon, label}) => {
  return (
    <MenuItemContainer>
      <Icon
        color="#444"
        size="0.85em"
      />
      <MenuItemLabel>{label}</MenuItemLabel>
    </MenuItemContainer>
  );
}

export default MenuItem;
