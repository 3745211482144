import React from 'react';
import styled from 'styled-components';
import Tip from './Tip';
import {MdContentCopy} from 'react-icons/md';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import getAlerter from './Alerter';
import MenuItem from './MenuItem';

const CopyToClipboardContainer = styled.div`
  cursor: pointer;
  outline: 0;
`;

const CopyToClipboardMenuItem = ({value, label, tip}) => {
  const alerter = getAlerter();
  const copiedToClipboard = () => {
    alerter.popSuccess(<span>Copied <strong>{value}</strong> to the clipboard.</span>)
  };
  return (
    <Tip
      content={tip}
    >
      <CopyToClipboardContainer>
        <CopyToClipboard
          text={value}
          onCopy={copiedToClipboard}
        >
          <span>
            <MenuItem icon={MdContentCopy} label={label} />
          </span>
        </CopyToClipboard>
      </CopyToClipboardContainer>
    </Tip>
  );
};

export default CopyToClipboardMenuItem;
