import React from 'react';
import styled from 'styled-components';
import {useToasts, ToastProvider, DefaultToastContainer, DefaultToast} from 'react-toast-notifications';

const ToastInnerContainer = styled.div`
  font-size: 1.3em;
`;
const Toast = ({children, ...props}) => (
  <DefaultToast {...props}>
    <ToastInnerContainer>
      {children}
    </ToastInnerContainer>
  </DefaultToast>
);

const ToastContainer = props => (
  <DefaultToastContainer
    style={{zIndex: 999999999}}
    {...props}
  />
);

const AlertProvider = ({children}) => {
  return (
    <ToastProvider components={{ToastContainer, Toast}} placement="top-center">
      {children}
    </ToastProvider>
  )
}

const getPopOptions = message => ({
  autoDismiss: true,
  autoDismissTimeout: Math.max((message.length || 0) * 75, 5000),
  pauseOnHover: true
});

class Alerter {
  constructor() {
    this.addToast = useToasts().addToast;
  }
  showError(err, action, options = {}) {
    this.addError(err, action, false, options);
  }
  popError(err, action, options = {}) {
    this.addError(err, action, true, options);
  }
  showSuccess(message, options = {}) {
    this.addSuccess(message, false, options);
  }
  popSuccess(message, options = {}) {
    this.addSuccess(message, true, options);
  }
  addError(err, action, pop, options = {}) {
    let message;
    if (err.name === 'ValidationError') {
      message = err.message;
    } else {
      console.log(err);
      message = `Oops, failed to ${action} due to a system error.`
    }
    this.addToast(message, {
      appearance: 'error',
      ...(pop ? getPopOptions(message) : {}),
      ...options
    });
  }
  addSuccess(message, pop, options = {}) {
    this.addToast(message, {
      appearance: 'success',
      ...(pop ? getPopOptions(message) : {}),
      ...options
    });
  }
}

let instance;

const getAlerter = () => {
  if (!instance) {
    instance = new Alerter();
  }
  return instance;
}

export {
  getAlerter as default,
  AlertProvider
};
