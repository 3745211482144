import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippy.js/react';
import isTouchDevice from './isTouchDevice';

const backgroundColor = 'rgb(58, 57, 51, 0.95)';

const StyledTippy = styled(Tippy)`
  background: ${backgroundColor};
  text-shadow: 0 1px 1px #333;
  font-size: 18px;
  &[x-placement^='top'] .tippy-arrow {
    border-top-color: ${backgroundColor};
  }
  &[x-placement^='bottom'] .tippy-arrow {
    border-bottom-color: ${backgroundColor};
  }
  &[x-placement^='left'] .tippy-arrow {
    border-left-color: ${backgroundColor};
  }
  &[x-placement^='right'] .tippy-arrow {
    border-right-color: ${backgroundColor};
  }
`;

const Tip = ({content, children, ...props}) => {
  const enabled = !isTouchDevice();
  return (
    <StyledTippy
      content={content}
      arrow={true}
      animation="fade"
      placement="right"
      distance={7}
      hideOnClick={false}
      enabled={enabled}
      {...props}
    >
      {children}
    </StyledTippy>
  );
};

export default Tip;
